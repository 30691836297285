var de_default = {
  translation: {
    cuda: {
      languages: {
        en: "English",
        fr: "Fran\xE7ais",
        ja: "\u65E5\u672C\u8A9E",
        de: "Deutsch",
        it: "Italienisch"
      },
      auth: {
        logout: "Ausloggen",
        checking: "Login verifizieren",
        signInError: "Fehler  bim Login. Bitte verifizieren Sie die Login-Daten and versuchen Sie es erneut.",
        switchAccount: "Tenant wechseln",
        unauthorized: "Erlaubnis abgelehn",
        unauthorizedMore: "Sie verf\xFCgen nicht \xFCber die erforderlichen Berechtigungen f\xFCr den Zugriff auf diesen Dienst. Bitte wenden Sie sich an Ihren Administrator.",
        defaultAccount: "Standard Tenant",
        profile: "Profil",
        signOut: "Abmelden"
      },
      supportAccess: {
        title: {
          enable: "Support Zugang aktivieren",
          disable: "Support Zugang deaktivieren"
        },
        body: {
          enable: "Aktivieren Sie den Support Zugang, damit die Mitarbeiter des Barracuda Support Konfigurations\xE4nderungen in Ihrem Namen vornehmen k\xF6nnen. Alle vom Barracuda Support vorgenommenen Konfigurations\xE4nderungen werden im Audit-Protokoll festgehalten.",
          disable: "Support Zugang deaktivieren. Alle vom Barracuda Support vorgenommenen Konfigurations\xE4nderungen sind im Audit-Protokoll sichtbar."
        }
      },
      layout: {
        hideMenu: "Men\xFC verstecken",
        showMenu: "Men\xFC anzeigen"
      },
      buttons: {
        save: "Sichern",
        add: "Neu",
        edit: "\xC4ndern",
        delete: "L\xF6schen",
        next: "Weiter",
        finish: "Ende",
        reset: "Zur\xFCcksetzen",
        cancel: "Abbrechen",
        back: "Zur\xFCck",
        confirm: "Best\xE4tigen",
        filters: {
          add: "Neuer Filter",
          clear: "Filter l\xF6schen"
        },
        downloadCsv: "CSV  runterladen",
        return: "nach Hause"
      },
      cards: {
        loading: "Laden\u2026",
        noMessages: "Keine Nachrichten verf\xFCgbar",
        noData: "Keine Ergebnisse gefunden",
        noListData: "Keine Ergebnisse gefunden",
        noTableData: "Keine Ergebnisse gefunden",
        noEventData: "Keine Ergebnisse gefunden",
        overview: "Gesamt"
      },
      footer: {
        copyright: "Barracuda Networks, Inc.  Alle Rechte vorbehalten.",
        privacyPolicy: "Privacy Policy",
        cookies: "Cookie Settings"
      },
      gdpr: {
        title: "EU-Cookie Akzeptanz",
        body: "Um Ihr Benutzererlebnis zu verbessern, verwendet diese Website Cookies. Durch die weitere Nutzung der Website erkl\xE4ren Sie sich damit einverstanden.",
        link: "<1>Privacy Policy</1> anschauen",
        accept: "Akzeptieren"
      },
      inputs: {
        file: {
          browserUnsupported: "Das Hochladen und \xDCberpr\xFCfen von Zertifikaten wird in diesem Browser nicht unterst\xFCtzt. Bitte verwenden Sie einen aktuellen Firefox-, Chrome-, Edge- oder Safari-Browser.",
          uploadError: "Beim Hochladen/Lesen der Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
          buttonLabel: "Datei hochladen"
        },
        select: {
          empty: "Leer"
        },
        autoSelect: {
          generated: "Auto-Generiert"
        },
        selectArray: {
          all: "Alle",
          typeToSearch: "Tippen um zu suchen",
          showing: "{{current}} von {{total}} werden gezeigt. ",
          unknownChipText: "Unbekannt"
        },
        selectSerial: {
          expandSectionButtonText: "fehlenden Secure Connector durch Serial/Linking Code hinzuf\xFCgen",
          helpText: "Diese finden Sie in Ihrer Best\xE4tigungs-E-Mail von Barracuda Networks",
          searchPlaceholder: "Seriennummer suchen",
          serial: "Seriennummer",
          linkingCode: "Linking Code",
          selectedSerials: "Ausgew\xE4hlte Seriennummern"
        },
        timeWindow: {
          abbrevDaysOfWeek: {
            mon: "Mon",
            tue: "Die",
            wed: "Mit",
            thu: "Don",
            fri: "Fre",
            sat: "Sam",
            sun: "Son"
          },
          daily: "D\xE4glich",
          from: "Von",
          to: "bis"
        },
        table: {
          noDataMessage: "Nichts hinzugef\xFCgt."
        },
        booleanCheckListInput: {
          categories: "Kategorien",
          selectAll: "Alle ausw\xE4hlen",
          selectedItemsStatus: "({{checkedItems}} von {{allItems}} ausgew\xE4hlt)"
        },
        IconBrowser: {
          iconSelectorText: "W\xE4hlen Sie ein vordefiniertes Symbol oder laden Sie ein eigenes hoch.<0></0> Unterst\xFCtzte Dateiformate: JPEG, PNG, GIF und SVG",
          useCustomText: "Eigenes Symbol verwenden:",
          customApplicationText: "App-Symbol:",
          searchText: "Symbole suchen:",
          placeholderText: "Symbole suchen...",
          noIcons: "Keine Symbole gefunden"
        }
      },
      table: {
        xOfY: "Eintr\xE4ge:",
        noData: "Nichts gefunden.",
        noDataWithFilter: "Es wurden keine Ergebnisse gefunden, die Ihren aktuellen Filtern entsprechen.",
        rowError_single: "{{message}}",
        rowError_multiple: "Reihe {{rowNumber}}: {{message}}",
        rowDetails: "Details zeigen",
        autocomplete: {
          showing: " {{current}} von {{total}} werden angezeigt ",
          showingMore: " {{from}} bis {{to}} von {{total}} Ergebnissen angezeigt. ",
          showAll: "All anzeigen",
          showMore: "Mehr anzeigen",
          addEntry: "Hinzuf\xFCgen '{{value}}'",
          search: "Tippen zum suchen"
        },
        columnSelector: {
          buttonLabel: "Spalten editieren",
          dialogTitle: "Angezeigte Spalten editieren"
        },
        footer: {
          loading: "Laden",
          end: "Ende der Tabelle"
        }
      },
      form: {
        dirtyNavigationCheck: "Sind Sie sicher, dass Sie gehen wollen? Alle nicht gespeicherten \xC4nderungen gehen verloren."
      },
      charts: {
        time: {
          labels: {
            day: "Tag",
            week: "Woche",
            month: "Monat",
            year: "Jahr"
          },
          dates: {
            monday: "Montag",
            tuesday: "Dienstag",
            wednesday: "Mittwoch",
            thursday: "Donnerstag",
            friday: "Freitag",
            saturday: "Samstag",
            sunday: "Sonntag",
            january: "Januar",
            february: "Februar",
            march: "M\xE4rz",
            april: "April",
            may: "Mai",
            june: "Juni",
            july: "Juli",
            august: "August",
            september: "September",
            october: "Oktober",
            november: "November",
            december: "Dezember"
          }
        },
        count: {
          total: "Gesamt"
        }
      },
      notification: {
        title: "Warnung",
        ok: "OK"
      },
      select: {
        noOptions: "Keine Optionen"
      },
      state: {
        error: "Fehler",
        ok: "OK",
        unknown: "Unbekannt",
        warning: "Warnung",
        loading: " "
      },
      validation: {
        alphaNumeric: "darf nur alphanumerische Zeichen enthalten",
        cidr: "muss eine g\xFCltige CIDR sein",
        dateOnAfter: "Datum muss am oder nach dem {{date}} liegen",
        dateOnBefore: "Datum muss am oder vor dem {{date}} liegen",
        int: "muss eine ganze Zahl sein",
        ip: "muss eine g\xFCltige IP-Adresse sein",
        privateIp: "muss eine g\xFCltige  PRIVATE IP-Adresse sein",
        ipCidr: "muss eine g\xFCltige IP-Adresse oder CIDR sein",
        privateIpCidr: "muss ein privates Netz sein",
        email: "muss eine g\xFCltige E-Mail-Adresse sein",
        domain: "muss eine g\xFCltige Dom\xE4ne sein",
        wildcardDomain: "muss eine g\xFCltige Domain sein, Wildcards (*) sind erlaubt",
        lengthMax: "muss {{length}} oder weniger Zeichen enthalten",
        lengthMin: "muss contain {{length}} oder mehr Zeichen enthalten",
        passwordConfirm: "eingegebene Werte stimmen nicht \xFCberein",
        required: "erforderlich",
        duplicate: "darf keine Duplikate enthalten",
        valueMin: "muss {{min}} oder mehr sein",
        valueMax: "darf {{max}} oder weniger sein"
      },
      delete: {
        title: "L\xF6schen best\xE4tigen ",
        message: "Sind Sie sicher, dass Sie diesen Eintrag l\xF6schen m\xF6chten?",
        ok: "Ok",
        cancel: "Abbrechen"
      },
      action: {
        title: "Aktion best\xE4tigen",
        message: "Sind Sie sicher, dass Sie diese Aktion durchf\xFChren wollen?",
        confirm: "Best\xE4tigen",
        cancel: "Abbrechen"
      },
      save: {
        title: "Speichern best\xE4tigen",
        message: "Sind Sie sicher, dass Sie diesen Eintrag speichern wollen"
      },
      unsupported: {
        browser: "Wir bitten vielmals um Entschuldigung, dieser Browser wird nicht unterst\xFCtzt."
      },
      error: {
        message: "Wir bitten vielmals um Entschuldigung. Etwas ist schief gelaufen."
      },
      resourceNotFound: {
        subtitle: "Ressource nicht gefunden",
        content: "Wir bitten vielmals um Entschuldigung. Die von Ihnen gesuchte Ressource konnte nicht gefunden werden."
      },
      comingSoonPage: {
        iconHeader: "Demn\xE4chst verf\xFCgbar!",
        textHeader: "Diese Funktion befindet sich noch in der Entwicklung.",
        text: "bitte schauen Sie bald wieder rein !"
      },
      manageProfile: "Profil verwalten"
    },
    httpError: {
      400: "Ung\xFCltige Anfrage. Versuchen Sie es bitte sp\xE4ter noch einmal.",
      401: "Sie sind nicht berechtigt, diese Anfrage durchzuf\xFChren. Melden Sie sich bitte an.",
      403: "Sie verf\xFCgen nicht \xFCber die Berechtigungen, um diese Anfrage durchzuf\xFChren.",
      404: "Das angeforderte Element wurde nicht gefunden.",
      500: "Unbekannter Fehler aufgetreten. Versuchen Sie es bitte sp\xE4ter noch einmal.",
      501: "Unbekannte Anfrage.",
      502: "SecureEdge Service konnte nicht kontaktiert werden. Der Service befindet sich m\xF6glicherweise in Wartung. Versuchen Sie es bitte sp\xE4ter noch einmal.",
      503: "SecureEdge Service konnte nicht kontaktiert werden. Der Service befindet sich m\xF6glicherweise in Wartung. Versuchen Sie es bitte sp\xE4ter noch einmal.",
      504: "SecureEdge Service konnte nicht kontaktiert werden. Der Service befindet sich m\xF6glicherweise in Wartung. Versuchen Sie es bitte sp\xE4ter noch einmal."
    }
  }
};
export {
  de_default as default
};
