var en_default = {
  translation: {
    cuda: {
      languages: {
        en: "English",
        fr: "Fran\xE7ais",
        ja: "\u65E5\u672C\u8A9E",
        de: "Deutsch",
        it: "Italiano"
      },
      auth: {
        logout: "Logout",
        signOut: "Sign out",
        checking: "Verifying Authentication",
        signInError: "Error occurred signing in. Please check your credentials, and try again.",
        switchAccount: "Switch Tenant",
        unauthorized: "Permission Denied",
        unauthorizedMore: "You do not have the relevant permissions to access this service. Please contact your administrator.",
        defaultAccount: "Default Tenant",
        profile: "Profile"
      },
      supportAccess: {
        title: {
          enable: "Enable support access",
          disable: "Disable support access"
        },
        body: {
          enable: "Enable support access to allow Barracuda Support technicians to make configuration changes on your behalf. Any configuration changes done by Barracuda support are tracked in the Audit Log.",
          disable: "Disable support access. All configuration changes done by Barracuda support are visible in the Audit Log."
        }
      },
      manageProfile: "Manage profile",
      layout: {
        hideMenu: "Hide Menu",
        showMenu: "Show Menu"
      },
      buttons: {
        save: "Save",
        add: "Add",
        edit: "Edit",
        delete: "Delete",
        next: "Next",
        finish: "Finish",
        reset: "Reset",
        cancel: "Cancel",
        back: "Back",
        confirm: "Confirm",
        filters: {
          add: "Add filter",
          clear: "Clear Filters"
        },
        downloadCsv: "Download CSV",
        return: "Home"
      },
      cards: {
        loading: "Loading\u2026",
        noMessages: "No messages currently available",
        noData: "No results found",
        noListData: "No results found",
        noTableData: "No results found",
        noEventData: "No results found",
        overview: "Total"
      },
      footer: {
        copyright: "Barracuda Networks, Inc. All rights reserved.",
        privacyPolicy: "Privacy Policy",
        cookies: "Cookie Settings"
      },
      gdpr: {
        title: "EU Cookie Acceptance",
        body: "To enhance your experience, this site uses cookies. Continued use of the site constitutes your consent.",
        link: "View our <1>Privacy Policy</1>",
        accept: "Accept"
      },
      inputs: {
        file: {
          browserUnsupported: "Certificate upload and verification is not supported in this browser. Please use an up-to-date Firefox, Chrome, Edge or Safari browser.",
          uploadError: "Error occurred uploading/reading file. Please try again.",
          buttonLabel: "Upload File"
        },
        select: {
          empty: "None"
        },
        autoSelect: {
          generated: "Auto-generated"
        },
        selectArray: {
          all: "All",
          typeToSearch: "Type to search",
          showing: "Showing {{current}} of {{total}}. ",
          unknownChipText: "Unknown"
        },
        selectSerial: {
          expandSectionButtonText: "Add missing Secure Connector by Serial/Linking Code",
          helpText: "These are found on your Barracuda Networks confirmation email",
          searchPlaceholder: "Search serials",
          serial: "Serial",
          linkingCode: "Linking Code",
          selectedSerials: "Selected Serials"
        },
        timeWindow: {
          abbrevDaysOfWeek: {
            mon: "Mon",
            tue: "Tue",
            wed: "Wed",
            thu: "Thu",
            fri: "Fri",
            sat: "Sat",
            sun: "Sun"
          },
          daily: "Daily",
          from: "From",
          to: "to"
        },
        table: {
          noDataMessage: "No items added."
        },
        booleanCheckListInput: {
          categories: "Categories",
          selectAll: "Select All",
          selectedItemsStatus: "({{checkedItems}} out of {{allItems}} selected)"
        },
        IconBrowser: {
          iconSelectorText: "Choose a predefined icon or upload a custom one.<0></0> Supported file formats are JPEG, PNG, GIF AND SVG",
          useCustomText: "Use custom icon:",
          customApplicationText: "Application Icon:",
          searchText: "Search icons:",
          placeholderText: "Search icons...",
          noIcons: "No icons found"
        }
      },
      table: {
        xOfY: "Items:",
        noData: "No results found.",
        noDataWithFilter: "No results found matching your current filters.",
        rowError_single: "{{message}}",
        rowError_multiple: "Row {{rowNumber}}: {{message}}",
        rowDetails: "Show Details",
        autocomplete: {
          showing: "Showing {{current}} of {{total}} results ",
          showingMore: "Showing {{from}} to {{to}} of {{total}} results. ",
          showAll: "Show All",
          showMore: "Show More",
          addEntry: "Add '{{value}}'",
          search: "Type to search"
        },
        columnSelector: {
          buttonLabel: "Edit columns",
          dialogTitle: "Edit Visible Columns"
        },
        footer: {
          loading: "Loading",
          end: "End of Table"
        }
      },
      form: {
        dirtyNavigationCheck: "Are you sure you want to leave? Any unsaved edits will be lost."
      },
      charts: {
        time: {
          labels: {
            day: "Day",
            week: "Week",
            month: "Month",
            year: "Year"
          },
          dates: {
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday",
            january: "January",
            february: "February",
            march: "March",
            april: "April",
            may: "May",
            june: "June",
            july: "July",
            august: "August",
            september: "September",
            october: "October",
            november: "November",
            december: "December"
          }
        },
        count: {
          total: "Total"
        }
      },
      notification: {
        title: "Warning",
        ok: "OK"
      },
      select: {
        noOptions: "No Options"
      },
      state: {
        error: "Error",
        ok: "OK",
        unknown: "Unknown",
        warning: "Warning",
        loading: " "
      },
      validation: {
        alphaNumeric: "must only contain alpha-numeric characters",
        cidr: "must be a valid CIDR",
        dateOnAfter: "date must be on or after {{date}}",
        dateOnBefore: "date must be on or before {{date}}",
        int: "must be an integer",
        ip: "must be a valid IP address",
        privateIp: "must be a valid Private IP address",
        ipCidr: "must be a valid IP address or CIDR",
        privateIpCidr: "must be a private network",
        email: "must be a valid email address",
        domain: "must be a valid domain",
        wildcardDomain: "must be a valid domain, wildcards (*) are permitted",
        lengthMax: "must contain {{length}} or less characters",
        lengthMin: "must contain {{length}} or more characters",
        passwordConfirm: "entered values do not match",
        required: "required",
        duplicate: "must not contain duplicates",
        valueMin: "must be {{min}} or more",
        valueMax: "must be {{max}} or less"
      },
      delete: {
        title: "Confirm Delete",
        message: "Are you sure you want to delete this item?",
        ok: "Ok",
        cancel: "Cancel"
      },
      action: {
        title: "Confirm Action",
        message: "Are you sure you want to perform this action?",
        confirm: "Confirm",
        cancel: "Cancel"
      },
      save: {
        title: "Confirm Save",
        message: "Are you sure you want to save this item?"
      },
      unsupported: {
        browser: "Sorry, this browser is not supported."
      },
      error: {
        message: "Something went wrong."
      },
      resourceNotFound: {
        subtitle: "Resource not found",
        content: "The resource you are looking for could not be found."
      },
      comingSoonPage: {
        iconHeader: "Coming Soon!",
        textHeader: "This feature is still in development.",
        text: "Please check back soon!"
      }
    },
    httpError: {
      400: "Invalid request. Please try again later.",
      401: "You are not authorized to perform this request. Please login.",
      403: "You do not have the permissions to perform this request.",
      404: "Requested item was not found.",
      500: "Unknown error occurred. Please try again later.",
      501: "Unknown request.",
      502: "Cannot contact the SecureEdge service. The service may be in maintenance. Please try again later.",
      503: "Cannot contact the SecureEdge service. The service may be in maintenance. Please try again later.",
      504: "Cannot contact the SecureEdge service. The service may be in maintenance. Please try again later."
    }
  }
};
export {
  en_default as default
};
