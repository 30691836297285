var ja_default = {
  translation: {
    cuda: {
      languages: {
        en: "English",
        fr: "Francais",
        ja: "\u65E5\u672C\u8A9E",
        de: "Deutsch",
        it: "Italiano"
      },
      auth: {
        logout: "\u30ED\u30B0\u30A2\u30A6\u30C8",
        checking: "\u8A8D\u8A3C\u3092\u78BA\u8A8D\u3057\u3066\u3044\u307E\u3059\u3002",
        signInError: "\u30B5\u30A4\u30F3\u30A4\u30F3\u4E2D\u306B\u30A8\u30E9\u30FC\u304C\u767A\u751F\u3057\u307E\u3057\u305F\u3002\u8A8D\u8A3C\u60C5\u5831\u3092\u78BA\u8A8D\u3057\u3066\u3001\u3082\u3046\u4E00\u5EA6\u304A\u8A66\u3057\u304F\u3060\u3055\u3044\u3002",
        switchAccount: "\u30C6\u30CA\u30F3\u30C8\u306E\u5207\u308A\u66FF\u3048",
        unauthorized: "\u6A29\u9650\u304C\u3042\u308A\u307E\u305B\u3093\u3002",
        unauthorizedMore: "\u3053\u306E\u30B5\u30FC\u30D3\u30B9\u306B\u30A2\u30AF\u30BB\u30B9\u3059\u308B\u305F\u3081\u306E\u9069\u5207\u306A\u6A29\u9650\u304C\u3042\u308A\u307E\u305B\u3093\u3002\u7BA1\u7406\u8005\u306B\u304A\u554F\u3044\u5408\u308F\u305B\u304F\u3060\u3055\u3044\u3002",
        defaultAccount: "\u65E2\u5B9A\u306E\u30C6\u30CA\u30F3\u30C8",
        profile: "\u30D7\u30ED\u30D5\u30A1\u30A4\u30EB",
        signOut: "\u30B5\u30A4\u30F3\u30A2\u30A6\u30C8"
      },
      supportAccess: {
        title: {
          enable: "\u30B5\u30DD\u30FC\u30C8\u30A2\u30AF\u30BB\u30B9\u3092\u6709\u52B9\u306B\u3059\u308B\u3002",
          disable: "\u30B5\u30DD\u30FC\u30C8\u30A2\u30AF\u30BB\u30B9\u3092\u7121\u52B9\u306B\u3059\u308B\u3002"
        },
        body: {
          enable: "\u30B5\u30DD\u30FC\u30C8\u30A2\u30AF\u30BB\u30B9\u3092\u6709\u52B9\u306B\u3059\u308B\u3068\u3001Barracuda\u306E\u30B5\u30DD\u30FC\u30C8\u6280\u8853\u8005\u304C\u304A\u5BA2\u69D8\u306B\u4EE3\u308F\u3063\u3066\u69CB\u6210\u5909\u66F4\u3092\u884C\u3046\u3053\u3068\u304C\u3067\u304D\u308B\u3088\u3046\u306B\u306A\u308A\u307E\u3059\u3002Barracuda\u30B5\u30DD\u30FC\u30C8\u306B\u3088\u3063\u3066\u884C\u308F\u308C\u305F\u69CB\u6210\u5909\u66F4\u306F\u3059\u3079\u3066\u3001\u76E3\u67FB\u30ED\u30B0\u3067\u8FFD\u8DE1\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
          disable: "\u30B5\u30DD\u30FC\u30C8\u30A2\u30AF\u30BB\u30B9\u3092\u7121\u52B9\u306B\u3057\u307E\u3059\u3002Barracuda\u30B5\u30DD\u30FC\u30C8\u306B\u3088\u3063\u3066\u884C\u308F\u308C\u305F\u3059\u3079\u3066\u306E\u69CB\u6210\u5909\u66F4\u306F\u3001\u76E3\u67FB\u30ED\u30B0\u306B\u8868\u793A\u3055\u308C\u307E\u3059\u3002"
        }
      },
      layout: {
        hideMenu: "\u30E1\u30CB\u30E5\u30FC\u975E\u8868\u793A",
        showMenu: "\u30E1\u30CB\u30E5\u30FC\u8868\u793A"
      },
      buttons: {
        save: "\u4FDD\u5B58",
        add: "\u8FFD\u52A0",
        edit: "\u7DE8\u96C6",
        delete: "\u524A\u9664",
        next: "\u6B21\u3078",
        finish: "\u5B8C\u4E86",
        reset: "\u30EA\u30BB\u30C3\u30C8",
        cancel: "\u30AD\u30E3\u30F3\u30BB\u30EB",
        back: "\u623B\u308B",
        confirm: "\u78BA\u8A8D",
        filters: {
          add: "\u30D5\u30A3\u30EB\u30BF\u30FC\u306E\u8FFD\u52A0",
          clear: "\u30D5\u30A3\u30EB\u30BF\u30FC\u306E\u30AF\u30EA\u30A2"
        },
        downloadCsv: "CSV\u306E\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9",
        return: "\u30DB\u30FC\u30E0"
      },
      cards: {
        loading: "\u8AAD\u307F\u8FBC\u307F\u4E2D\u2026",
        noMessages: "\u73FE\u5728\u5229\u7528\u53EF\u80FD\u306A\u30E1\u30C3\u30BB\u30FC\u30B8\u306F\u3042\u308A\u307E\u305B\u3093\u3002",
        noData: "\u7D50\u679C\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3002",
        noListData: "\u7D50\u679C\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3002",
        noTableData: "\u7D50\u679C\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3002",
        noEventData: "\u7D50\u679C\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3002",
        overview: "\u5408\u8A08"
      },
      footer: {
        copyright: "Barracuda Networks, Inc. \u7121\u65AD\u8EE2\u8F09\u7981\u6B62",
        privacyPolicy: "\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC",
        cookies: "Cookie\u306E\u8A2D\u5B9A"
      },
      gdpr: {
        title: "EU Cookie\u306E\u627F\u8A8D",
        body: "\u30E6\u30FC\u30B6\u30FC \u30A8\u30AF\u30B9\u30DA\u30EA\u30A8\u30F3\u30B9\u3092\u5411\u4E0A\u3055\u305B\u308B\u305F\u3081\u3001\u3053\u306E\u30B5\u30A4\u30C8\u3067\u306F Cookie \u3092\u4F7F\u7528\u3057\u3066\u3044\u307E\u3059\u3002\u3053\u306E\u30B5\u30A4\u30C8\u3092\u5F15\u304D\u7D9A\u304D\u4F7F\u7528\u3059\u308B\u3053\u3068\u306F\u3001\u30E6\u30FC\u30B6\u30FC\u69D8\u306E\u540C\u610F\u3068\u307F\u306A\u3055\u308C\u307E\u3059\u3002\u3002",
        link: "<1>\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC</1>\u3092\u8868\u793A",
        accept: "\u540C\u610F"
      },
      inputs: {
        file: {
          browserUnsupported: "\u3053\u306E\u30D6\u30E9\u30A6\u30B6\u30FC\u3067\u306F\u8A3C\u660E\u66F8\u306E\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u3068\u691C\u8A3C\u306F\u30B5\u30DD\u30FC\u30C8\u3055\u308C\u3066\u3044\u307E\u305B\u3093\u3002\u6700\u65B0\u306E Firefox\u3001Chrome\u3001Edge\u3001\u307E\u305F\u306F Safari\u30D6\u30E9\u30A6\u30B6\u30FC\u3092\u3054\u5229\u7528\u304F\u3060\u3055\u3044\u3002",
          uploadError: "\u30D5\u30A1\u30A4\u30EB\u306E\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9/\u8AAD\u8FBC\u307F\u4E2D\u306B\u30A8\u30E9\u30FC\u304C\u767A\u751F\u3057\u307E\u3057\u305F\u3002\u3082\u3046\u4E00\u5EA6\u304A\u8A66\u3057\u304F\u3060\u3055\u3044\u3002",
          buttonLabel: "\u30D5\u30A1\u30A4\u30EB\u306E\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9"
        },
        select: {
          empty: "\u306A\u3057"
        },
        autoSelect: {
          generated: "\u81EA\u52D5\u751F\u6210\u6E08\u307F"
        },
        selectArray: {
          all: "\u3059\u3079\u3066",
          typeToSearch: "\u5165\u529B\u3057\u3066\u691C\u7D22",
          showing: "{{total}}\u4EF6\u306E\u5185 {{current}}\u4EF6\u3092\u8868\u793A\u4E2D\u3067\u3059\u3002 ",
          unknownChipText: "\u4E0D\u660E"
        },
        selectSerial: {
          expandSectionButtonText: "\u30B7\u30EA\u30A2\u30EB/\u30EA\u30F3\u30AF\u30B3\u30FC\u30C9\u3092\u767B\u9332\u3057\u3066\u4E00\u89A7\u306B\u306A\u3044\u30BB\u30AD\u30E5\u30A2\u30B3\u30CD\u30AF\u30BF\u3092\u8FFD\u52A0\u3057\u307E\u3059\u3002",
          helpText: "\u3053\u308C\u306F Barracuda Networks\u304B\u3089\u306E\u78BA\u8A8D\u30E1\u30FC\u30EB\u306B\u8A18\u8F09\u3055\u308C\u3066\u3044\u307E\u3059\u3002",
          searchPlaceholder: "\u30B7\u30EA\u30A2\u30EB\u3092\u691C\u7D22",
          serial: "\u30B7\u30EA\u30A2\u30EB",
          linkingCode: "\u30EA\u30F3\u30AF\u30B3\u30FC\u30C9",
          selectedSerials: "\u9078\u629E\u3057\u305F\u30B7\u30EA\u30A2\u30EB"
        },
        timeWindow: {
          abbrevDaysOfWeek: {
            mon: "\u6708",
            tue: "\u706B",
            wed: "\u6C34",
            thu: "\u6728",
            fri: "\u91D1",
            sat: "\u571F",
            sun: "\u65E5"
          },
          daily: "\u6BCE\u65E5",
          from: "\u958B\u59CB",
          to: "\u7D42\u4E86"
        },
        table: {
          noDataMessage: "\u8FFD\u52A0\u3055\u308C\u305F\u9805\u76EE\u306F\u3042\u308A\u307E\u305B\u3093\u3002"
        },
        booleanCheckListInput: {
          categories: "\u30AB\u30C6\u30B4\u30EA\u30FC",
          selectAll: "\u3059\u3079\u3066\u9078\u629E",
          selectedItemsStatus: "({{allItems}}\u306E\u3046\u3061{{checkedItems}}\u9805\u76EE\u304C\u9078\u629E\u6E08\u307F)"
        },
        IconBrowser: {
          iconSelectorText: "\u5B9A\u7FA9\u6E08\u307F\u30A2\u30A4\u30B3\u30F3\u3092\u9078\u3076\u304B\u30AB\u30B9\u30BF\u30E0\u30A2\u30A4\u30B3\u30F3\u3092\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u3057\u3066\u304F\u3060\u3055\u3044\u3002<0></0>\u5BFE\u5FDC\u30D5\u30A1\u30A4\u30EB\u5F62\u5F0F\uFF1AJPEG\u3001PNG\u3001GIF\u3001SVG",
          useCustomText: "\u30AB\u30B9\u30BF\u30E0\u30A2\u30A4\u30B3\u30F3\u4F7F\u7528\uFF1A",
          customApplicationText: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u30A2\u30A4\u30B3\u30F3\uFF1A",
          searchText: "\u30A2\u30A4\u30B3\u30F3\u691C\u7D22\uFF1A",
          placeholderText: "\u30A2\u30A4\u30B3\u30F3\u691C\u7D22\u4E2D...",
          noIcons: "\u30A2\u30A4\u30B3\u30F3\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3002"
        }
      },
      table: {
        xOfY: "\u9805\u76EE:",
        noData: "\u7D50\u679C\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F\u3002",
        noDataWithFilter: "\u73FE\u5728\u306E\u30D5\u30A3\u30EB\u30BF\u30FC\u306B\u4E00\u81F4\u3059\u308B\u7D50\u679C\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3002",
        rowError_single: "{{message}}",
        rowError_multiple: "{{rowNumber}}\u884C: {{message}}",
        rowDetails: "\u8A73\u7D30\u306E\u8868\u793A",
        autocomplete: {
          showing: "{{total}}\u4EF6\u4E2D{{current}}\u4EF6\u306E\u7D50\u679C\u3092\u8868\u793A\u4E2D ",
          showingMore: "{{total}}\u4EF6\u4E2D{{from}}\u304B\u3089{{to}}\u307E\u3067\u306E\u7D50\u679C\u3092\u8868\u793A\u4E2D ",
          showAll: "\u3059\u3079\u3066\u8868\u793A",
          showMore: "\u3055\u3089\u306B\u8868\u793A",
          addEntry: "'{{value}}'\u3092\u8FFD\u52A0",
          search: "\u5165\u529B\u3057\u3066\u691C\u7D22"
        },
        columnSelector: {
          buttonLabel: "\u5217\u3092\u7DE8\u96C6",
          dialogTitle: "\u8868\u793A\u53EF\u80FD\u306A\u5217\u306E\u7DE8\u96C6"
        },
        footer: {
          loading: "\u8AAD\u307F\u8FBC\u3093\u3067\u3044\u307E\u3059\u3002",
          end: "\u8868\u306E\u6700\u5F8C"
        }
      },
      form: {
        dirtyNavigationCheck: "\u7D42\u4E86\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F \u4FDD\u5B58\u3057\u3066\u3044\u306A\u3044\u7DE8\u96C6\u5185\u5BB9\u306F\u5931\u308F\u308C\u307E\u3059\u3002"
      },
      charts: {
        time: {
          labels: {
            day: "\u65E5",
            week: "\u9031",
            month: "\u6708",
            year: "\u5E74"
          },
          dates: {
            monday: "\u6708",
            tuesday: "\u706B",
            wednesday: "\u6C34",
            thursday: "\u6728",
            friday: "\u91D1",
            saturday: "\u571F",
            sunday: "\u65E5",
            january: "1\u6708",
            february: "2\u6708",
            march: "3\u6708",
            april: "4\u6708",
            may: "5\u6708",
            june: "6\u6708",
            july: "7\u6708",
            august: "8\u6708",
            september: "9\u6708",
            october: "10\u6708",
            november: "11\u6708",
            december: "12\u6708"
          }
        },
        count: {
          total: "\u5408\u8A08"
        }
      },
      notification: {
        title: "\u8B66\u544A",
        ok: "OK"
      },
      select: {
        noOptions: "\u30AA\u30D7\u30B7\u30E7\u30F3\u306A\u3057"
      },
      state: {
        error: "\u30A8\u30E9\u30FC",
        ok: "OK",
        unknown: "\u4E0D\u660E",
        warning: "\u8B66\u544A",
        loading: " "
      },
      validation: {
        alphaNumeric: "\u82F1\u6570\u5B57\u306E\u307F\u8A18\u5165\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
        cidr: "\u6709\u52B9\u306A CIDR\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
        dateOnAfter: "\u65E5\u4ED8\u306F {{date}}\u4EE5\u964D\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
        dateOnBefore: "\u65E5\u4ED8\u306F {{date}}\u4EE5\u524D\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
        int: "\u6574\u6570\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
        ip: "\u6709\u52B9\u306A IP\u30A2\u30C9\u30EC\u30B9\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
        privateIp: "\u6709\u52B9\u306A\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8 IP\u30A2\u30C9\u30EC\u30B9\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
        ipCidr: "\u6709\u52B9\u306AIP\u30A2\u30C9\u30EC\u30B9\u307E\u305F\u306F CIDR\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
        privateIpCidr: "\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
        email: "\u6709\u52B9\u306A\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
        domain: "\u6709\u52B9\u306A\u30C9\u30E1\u30A4\u30F3\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
        wildcardDomain: "\u6709\u52B9\u306A\u30C9\u30E1\u30A4\u30F3\u3067\u306A\u3051\u308C\u3070\u306A\u3089\u305A\u3001\u30EF\u30A4\u30EB\u30C9\u30AB\u30FC\u30C9(*)\u304C\u5229\u7528\u53EF\u80FD\u3067\u3059\u3002",
        lengthMax: "{{length}}\u6587\u5B57\u4EE5\u4E0B\u3067\u3042\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002",
        lengthMin: "{{length}}\u6587\u5B57\u4EE5\u4E0A\u3067\u3042\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002",
        passwordConfirm: "\u5165\u529B\u3055\u308C\u305F\u5024\u304C\u4E00\u81F4\u3057\u307E\u305B\u3093\u3002",
        required: "\u5FC5\u9808",
        duplicate: "\u91CD\u8907\u304C\u542B\u307E\u308C\u3066\u3044\u3066\u306F\u306A\u308A\u307E\u305B\u3093\u3002",
        valueMin: "{{min}} \u4EE5\u4E0A\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
        valueMax: "{{max}} \u4EE5\u4E0B\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002"
      },
      delete: {
        title: "\u524A\u9664\u3092\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
        message: "\u3053\u306E\u9805\u76EE\u3092\u524A\u9664\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F",
        ok: "OK",
        cancel: "\u30AD\u30E3\u30F3\u30BB\u30EB"
      },
      action: {
        title: "\u30A2\u30AF\u30B7\u30E7\u30F3\u3092\u78BA\u8A8D",
        message: "\u3053\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u3092\u672C\u5F53\u306B\u5B9F\u884C\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F",
        confirm: "\u78BA\u8A8D",
        cancel: "\u30AD\u30E3\u30F3\u30BB\u30EB"
      },
      save: {
        title: "\u4FDD\u5B58\u306E\u78BA\u8A8D",
        message: "\u3053\u306E\u9805\u76EE\u3092\u4FDD\u5B58\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F"
      },
      unsupported: {
        browser: "\u7533\u3057\u8A33\u3042\u308A\u307E\u305B\u3093\u304C\u3001\u3053\u306E\u30D6\u30E9\u30A6\u30B6\u306F\u30B5\u30DD\u30FC\u30C8\u3055\u308C\u3066\u3044\u307E\u305B\u3093\u3002"
      },
      error: {
        message: "\u4F55\u3089\u304B\u306E\u554F\u984C\u304C\u767A\u751F\u3057\u307E\u3057\u305F\u3002"
      },
      resourceNotFound: {
        subtitle: "\u30EA\u30BD\u30FC\u30B9\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3002",
        content: "\u304A\u63A2\u3057\u306E\u30EA\u30BD\u30FC\u30B9\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F\u3002"
      },
      comingSoonPage: {
        iconHeader: "\u8FD1\u65E5\u516C\u958B\u4E88\u5B9A\uFF01",
        textHeader: "\u3053\u306E\u6A5F\u80FD\u306F\u307E\u3060\u958B\u767A\u4E2D\u3067\u3059\u3002",
        text: "\u8FD1\u65E5\u4E2D\u306B\u518D\u5EA6\u3054\u78BA\u8A8D\u304F\u3060\u3055\u3044\uFF01"
      },
      manageProfile: "\u30D7\u30ED\u30D5\u30A1\u30A4\u30EB\u306E\u7BA1\u7406"
    },
    httpError: {
      400: "\u7121\u52B9\u306A\u30EA\u30AF\u30A8\u30B9\u30C8\u3067\u3059\u3002\u3057\u3070\u3089\u304F\u3057\u3066\u304B\u3089\u3082\u3046\u4E00\u5EA6\u304A\u8A66\u3057\u304F\u3060\u3055\u3044\u3002",
      401: "\u3053\u306E\u30EA\u30AF\u30A8\u30B9\u30C8\u3092\u5B9F\u884C\u3059\u308B\u6A29\u9650\u304C\u3042\u308A\u307E\u305B\u3093\u3002\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
      403: "\u3053\u306E\u30EA\u30AF\u30A8\u30B9\u30C8\u3092\u5B9F\u884C\u3059\u308B\u6A29\u9650\u304C\u3042\u308A\u307E\u305B\u3093\u3002",
      404: "\u30EA\u30AF\u30A8\u30B9\u30C8\u3055\u308C\u305F\u30A2\u30A4\u30C6\u30E0\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3002",
      500: "\u4E0D\u660E\u306A\u30A8\u30E9\u30FC\u304C\u767A\u751F\u3057\u307E\u3057\u305F\u3002\u3057\u3070\u3089\u304F\u3057\u3066\u304B\u3089\u3082\u3046\u4E00\u5EA6\u304A\u8A66\u3057\u304F\u3060\u3055\u3044\u3002",
      501: "\u4E0D\u660E\u306A\u30EA\u30AF\u30A8\u30B9\u30C8\u3067\u3059\u3002",
      502: "SecureEdge \u30B5\u30FC\u30D3\u30B9\u306B\u63A5\u7D9A\u3067\u304D\u307E\u305B\u3093\u3002\u30B5\u30FC\u30D3\u30B9\u304C\u30E1\u30F3\u30C6\u30CA\u30F3\u30B9\u4E2D\u306E\u53EF\u80FD\u6027\u304C\u3042\u308A\u307E\u3059\u3002\u3057\u3070\u3089\u304F\u3057\u3066\u304B\u3089\u3082\u3046\u4E00\u5EA6\u304A\u8A66\u3057\u304F\u3060\u3055\u3044\u3002",
      503: "SecureEdge \u30B5\u30FC\u30D3\u30B9\u306B\u63A5\u7D9A\u3067\u304D\u307E\u305B\u3093\u3002\u30B5\u30FC\u30D3\u30B9\u304C\u30E1\u30F3\u30C6\u30CA\u30F3\u30B9\u4E2D\u306E\u53EF\u80FD\u6027\u304C\u3042\u308A\u307E\u3059\u3002\u3057\u3070\u3089\u304F\u3057\u3066\u304B\u3089\u3082\u3046\u4E00\u5EA6\u304A\u8A66\u3057\u304F\u3060\u3055\u3044\u3002",
      504: "SecureEdge \u30B5\u30FC\u30D3\u30B9\u306B\u63A5\u7D9A\u3067\u304D\u307E\u305B\u3093\u3002\u30B5\u30FC\u30D3\u30B9\u304C\u30E1\u30F3\u30C6\u30CA\u30F3\u30B9\u4E2D\u306E\u53EF\u80FD\u6027\u304C\u3042\u308A\u307E\u3059\u3002\u3057\u3070\u3089\u304F\u3057\u3066\u304B\u3089\u3082\u3046\u4E00\u5EA6\u304A\u8A66\u3057\u304F\u3060\u3055\u3044\u3002"
    }
  }
};
export {
  ja_default as default
};
