var fr_default = {
  translation: {
    cuda: {
      languages: {
        en: "English",
        fr: "Fran\xE7ais",
        ja: "\u65E5\u672C\u8A9E",
        de: "Deutsch",
        it: "Italiano"
      },
      auth: {
        logout: "D\xE9connexion",
        checking: "V\xE9rification de l'authentification",
        signInError: "Erreur durant l'identification. Veuillez v\xE9rifier vos identifiants et r\xE9essayer.",
        switchAccount: "Changer de client",
        unauthorized: "Permission refus\xE9e",
        unauthorizedMore: "Vous ne disposez pas des permissions n\xE9cessaires pour acc\xE9der \xE0 ce service. Veuillez contacter votre administrateur.",
        defaultAccount: "Client par d\xE9faut",
        profile: "Profil",
        signOut: "D\xE9connexion"
      },
      supportAccess: {
        title: {
          enable: "Activer l'acc\xE8s au support",
          disable: "D\xE9sactiver l'acc\xE8s au support"
        },
        body: {
          enable: "Le fait d'activer l'acc\xE8s au support permet aux techniciens de Barracuda Support de modifier la configuration \xE0 votre place. Toutes les modifications de configuration effectu\xE9es par Barracuda Support sont suivies dans le journal d'audit.",
          disable: "D\xE9sactivez l'acc\xE8s au support. Toutes les modifications de configuration effectu\xE9es par Barracuda Support sont visibles dans le journal d'audit."
        }
      },
      layout: {
        hideMenu: "Masquer un menu",
        showMenu: "Afficher un menu"
      },
      buttons: {
        save: "Enregistrer",
        add: "Ajouter",
        edit: "Modifier",
        delete: "Supprimer",
        next: "Suivant",
        finish: "Terminer ",
        reset: "R\xE9initialiser",
        cancel: "Annuler",
        back: "Retour",
        confirm: "Confirmer",
        filters: {
          add: "Ajouter un filtre",
          clear: "Effacer des filtres"
        },
        downloadCsv: "T\xE9l\xE9charger CSV",
        return: "Accueil"
      },
      cards: {
        loading: "Chargement...",
        noMessages: "Aucun message actuellement disponible",
        noData: "Aucun r\xE9sultat trouv\xE9",
        noListData: "Aucun r\xE9sultat trouv\xE9",
        noTableData: "Aucun r\xE9sultat trouv\xE9",
        noEventData: "Aucun r\xE9sultat trouv\xE9",
        overview: "Total"
      },
      footer: {
        copyright: "Barracuda Networks, Inc. Tous les droits r\xE9serv\xE9s.",
        privacyPolicy: "Politique relative \xE0 la confidentialit\xE9",
        cookies: "Param\xE8tres de cookies"
      },
      gdpr: {
        title: "Acceptation des cookies UE",
        body: "Pour am\xE9liorer votre exp\xE9rience, ce site utilise des cookies. Le fait de continuer \xE0 utiliser ce site constitue votre consentement.",
        link: "Afficher notre <1>Politique relative \xE0 la confidentialit\xE9</1>",
        accept: "Accepter"
      },
      inputs: {
        file: {
          browserUnsupported: "Le t\xE9l\xE9chargement et la v\xE9rification de certificat ne sont pas pris en charge dans ce navigateur. Veuillez mettre \xE0 jour votre navigateur Firefox, Chrome, Edge ou Safari.",
          uploadError: "Erreur durant le t\xE9l\xE9chargement/lecture du fichier. Veuillez r\xE9essayer.",
          buttonLabel: "T\xE9l\xE9charger fichier"
        },
        select: {
          empty: "Aucun"
        },
        autoSelect: {
          generated: "Autog\xE9n\xE9r\xE9"
        },
        selectArray: {
          all: "Tout",
          typeToSearch: "Saisir pour rechercher",
          showing: "Affichage de {{current}} sur {{total}}.  ",
          unknownChipText: "Inconnu"
        },
        selectSerial: {
          expandSectionButtonText: "Ajouter un Secure Connector avec un num\xE9ro de s\xE9rie/code de liaison",
          helpText: "Ils se trouvent dans votre e-mail de confirmation envoy\xE9 par Barracuda Networks",
          searchPlaceholder: "Rechercher des num\xE9ros de s\xE9ries",
          serial: "Num\xE9ro de s\xE9rie",
          linkingCode: "Code de liaison",
          selectedSerials: "Num\xE9ros de s\xE9ries s\xE9lectionn\xE9s"
        },
        timeWindow: {
          abbrevDaysOfWeek: {
            mon: "Lun",
            tue: "Mar",
            wed: "Mer",
            thu: "Jeu",
            fri: "Ven",
            sat: "Sam",
            sun: "Dim"
          },
          daily: "Quotidien",
          from: "De",
          to: "\xE0"
        },
        table: {
          noDataMessage: "Aucun \xE9l\xE9ment ajout\xE9."
        },
        booleanCheckListInput: {
          categories: "Cat\xE9gories",
          selectAll: "S\xE9lectionner tout",
          selectedItemsStatus: "({{checkedItems}} sur {{allItems}} s\xE9lectionn\xE9s)"
        },
        IconBrowser: {
          iconSelectorText: "Choisissez une ic\xF4ne pr\xE9d\xE9finie ou t\xE9l\xE9chargez une ic\xF4ne personnalis\xE9e.<0></0> Les formats de fichier JPEG, PNG, GIF et SVG sont pris en charge",
          useCustomText: "Utilisez une ic\xF4ne personnalis\xE9e :",
          customApplicationText: "Ic\xF4ne d'application :",
          searchText: "Recherchez des ic\xF4nes :",
          placeholderText: "Recherche d'ic\xF4nes...",
          noIcons: "Aucune ic\xF4ne trouv\xE9e"
        }
      },
      table: {
        xOfY: "\xC9l\xE9ments :",
        noData: "Aucun r\xE9sultat trouv\xE9.",
        noDataWithFilter: "Aucun r\xE9sultat trouv\xE9 correspondant \xE0 vos filtres actuels.",
        rowError_single: "{{message}}",
        rowError_multiple: "Rang {{rowNumber}} : {{message}}",
        rowDetails: "Afficher des d\xE9tails",
        autocomplete: {
          showing: "Affichage de {{current}} sur {{total}} r\xE9sultats  ",
          showingMore: "Affichage de {{from}} \xE0 {{to}} sur {{total}} r\xE9sultats  ",
          showAll: "Afficher tout",
          showMore: "Afficher plus",
          addEntry: "Ajouter \xAB\xA0{{value}}\xA0\xBB",
          search: "Saisir pour rechercher"
        },
        columnSelector: {
          buttonLabel: "Modifier des colonnes",
          dialogTitle: "Modifier des colonnes visibles"
        },
        footer: {
          loading: "Chargement",
          end: "Fin du tableau"
        }
      },
      form: {
        dirtyNavigationCheck: "\xCAtes-vous certain de vouloir sortir ? Toute modification sans enregistrement sera perdue."
      },
      charts: {
        time: {
          labels: {
            day: "Jour",
            week: "Semaine",
            month: "Mois",
            year: "Ann\xE9e"
          },
          dates: {
            monday: "Lundi",
            tuesday: "Mardi",
            wednesday: "Mercredi",
            thursday: "Jeudi",
            friday: "Vendredi",
            saturday: "Samedi",
            sunday: "Dimanche",
            january: "Janvier",
            february: "F\xE9vrier",
            march: "Mars",
            april: "Avril",
            may: "Mai",
            june: "Juin",
            july: "Juillet",
            august: "Ao\xFBt",
            september: "Septembre",
            october: "Octobre",
            november: "Novembre",
            december: "D\xE9cembre"
          }
        },
        count: {
          total: "Total"
        }
      },
      notification: {
        title: "Avertissement",
        ok: "OK"
      },
      select: {
        noOptions: "Aucune option"
      },
      state: {
        error: "Erreur",
        ok: "OK",
        unknown: "Inconnu",
        warning: "Avertissement",
        loading: " "
      },
      validation: {
        alphaNumeric: "doit contenir uniquement des caract\xE8res alphanum\xE9riques",
        cidr: "doit \xEAtre un CIDR valide",
        dateOnAfter: "la date doit \xEAtre le {{date}} ou apr\xE8s",
        dateOnBefore: "la date doit \xEAtre le {{date}} ou avant",
        int: "doit \xEAtre un nombre entier",
        ip: "doit \xEAtre une adresse IP valide",
        privateIp: "doit \xEAtre une adresse IP priv\xE9e valide",
        ipCidr: "doit \xEAtre un CIDR ou une adresse IP valide",
        privateIpCidr: "doit \xEAtre un r\xE9seau priv\xE9",
        email: "doit \xEAtre une adresse e-mail valide",
        domain: "doit \xEAtre un nom de domaine valide",
        wildcardDomain: "doit \xEAtre un nom de domaine valide et peut contenir  caract\xE8res g\xE9n\xE9riques (*)",
        lengthMax: "doit contenir {{length}} caract\xE8res ou plus",
        lengthMin: "doit contenir {{length}} caract\xE8res ou moins",
        passwordConfirm: "les valeurs saisies ne correspondent pas",
        required: "obligatoire",
        duplicate: "ne doit pas contenir de doublons",
        valueMin: "doit \xEAtre {{min}} ou plus",
        valueMax: "doit \xEAtre {{max}} ou moins"
      },
      delete: {
        title: "Confirmer Supprimer",
        message: "\xCAtes-vous certain de vouloir supprimer cet \xE9l\xE9ment ?",
        ok: "Ok",
        cancel: "Annuler"
      },
      action: {
        title: "Confirmer une action",
        message: "\xCAtes-vous certain de vouloir ex\xE9cuter cette action\xA0?",
        confirm: "Confirmer",
        cancel: "Annuler"
      },
      save: {
        title: "Confirmer Enregistrer",
        message: "\xCAtes-vous certain de vouloir enregistrer cet \xE9l\xE9ment ?"
      },
      unsupported: {
        browser: "D\xE9sol\xE9. Ce navigateur n'est pas pris en charge."
      },
      error: {
        message: "Un probl\xE8me est survenu."
      },
      resourceNotFound: {
        subtitle: "Impossible de trouver la ressource",
        content: "Impossible de trouver la ressource que vous recherchez."
      },
      comingSoonPage: {
        iconHeader: "Prochainement !",
        textHeader: "Cette fonctionnalit\xE9 est en cours de d\xE9veloppement.",
        text: "Revenez v\xE9rifier prochainement !"
      },
      manageProfile: "G\xE9rer profil"
    },
    httpError: {
      400: "Requ\xEAte invalide. Veuillez r\xE9essayer plus tard.",
      401: "Vous n'\xEAtes pas autoris\xE9 \xE0 effectuer cette requ\xEAte. Veuillez vous connecter.",
      403: "Vous ne disposez pas des permissions pour effectuer cette requ\xEAte.",
      404: "Impossible de trouver l'\xE9l\xE9ment de requ\xEAte.",
      500: "Une erreur inconnue s'est produite. Veuillez r\xE9essayer plus tard.",
      501: "Requ\xEAte inconnue.",
      502: "Impossible de contacter SecureEdge Service. Le service peut \xEAtre en maintenance. Veuillez r\xE9essayer plus tard.",
      503: "Impossible de contacter SecureEdge Service. Le service peut \xEAtre en maintenance. Veuillez r\xE9essayer plus tard.",
      504: "Impossible de contacter SecureEdge Service. Le service peut \xEAtre en maintenance. Veuillez r\xE9essayer plus tard."
    }
  }
};
export {
  fr_default as default
};
